<template>
  <a @click="browsePost"  class="thumb-post thumb-post--land">
    <div class="thumb-post__figure">
      <img :src="post.featured_image" />
    </div>
    <div class="thumb-post__content">
      <h2 class="thumb-post__title">
        {{ post.title }}
      </h2>
      <div class="thumb-post__tax">{{ post.mainCategory }}</div>
    </div>
  </a>
</template>

<script>
import Post from "./Mixins/Post";

export default {
  name: "BlogPostCardHero",
  mixins: [ Post ],
  methods: {
    Clicking()
    {
      this.$emit('clicked', this.post.title);
      this.browseProduct();
    }
  }
};
</script>
